/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import DocsMarkdownPage from "../../components/oss-doc-page"
import Layout from "../../layouts"
import Header from "../../components/header"
import { getCurrentDocsSection } from "../../utils/sidebar/item-list.js"

// remote markdown docs on coming from the OSS repo
function DocsTemplate({
  data: { mdx },
  location,
  pageContext: { next, prev },
}) {
  const page = mdx
  const { frontmatter } = page

  return (
    <Layout>
      <Header
        applyTopPadding={false}
        isFullWidth
        isFixed
        docType={getCurrentDocsSection(location.pathname)}
      />
      {/* DocsMarkdowPage -> DocSearchContent contains `SkipNavTarget` */}
      <DocsMarkdownPage page={page} location={location} prev={prev} next={next}>
        {frontmatter.issue && (
          <a
            href={page.frontmatter.issue}
            target="_blank"
            rel="noopener noreferrer"
          >
            See the issue relating to this stub on GitHub
          </a>
        )}
      </DocsMarkdownPage>
    </Layout>
  )
}

export default DocsTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      timeToRead
      tableOfContents
      fields {
        slug
        locale
        anchor
        relPath
      }
      frontmatter {
        title
        examples {
          label
          href
        }
        description
        overview
        issue
        disableTableOfContents
        tableOfContentsDepth
      }
    }
  }
`
